<template>
    <tr>
        <td
            v-if="editTermReport"
        >
            <v-autocomplete
                v-model="recordSubject"
                :items="subjects"
                dense
                item-text="abbr"
                item-value="id"
                hide-details
                solo
                flat
                class="text-caption"
                @change="updateRecord"                                          
            ></v-autocomplete>           
        </td>
        <td
            v-else
        >
            {{ record.abbr }}
        </td>        
        <td
            v-if="editTermReport"
        >
            <v-text-field
                v-model="record.course_mark"
                dense
                hide-details                
                min=0
                max=100
                outlined
                style="font-size:0.9rem"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
                @blur="updateRecord"
                class="mt-1"
                :disabled="assesmentCourse.disable"
                :filled="assesmentCourse.disable"                  
            >
            </v-text-field>
            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                        
                        block
                        height="15"
                        class="my-1 pa-0"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        tile
                        @click="setAttendance(assesmentCourse, true)"
                    >{{ assesmentCourse.button }}</v-btn>
                </template>
                <span>{{ assesmentCourse.tooltip }}</span>
            </v-tooltip>  
            
        </td>
        <td
            v-else
        >
            {{ record.course_mark }}
        </td>

        <td
            v-if="editTermReport"
        >
            <v-text-field
                v-model="record.exam_mark"
                dense
                hide-details                
                min=0
                max=100
                outlined
                style="font-size:0.9rem"
                @blur="updateRecord"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
                class="mt-1" 
                :disabled="assesmentExam.disable"
                :filled="assesmentExam.disable"      
            >
            </v-text-field>

            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                        
                        block
                        height="15"
                        class="my-1 pa-0"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        tile
                        @click="setAttendance(assesmentExam, true)"
                    >{{ assesmentExam.button }}</v-btn>
                </template>
                <span>{{ assesmentExam.tooltip }}</span>
            </v-tooltip> 
        </td>

        <td
            v-else
        >
            {{ record.exam_mark }}
        </td>

        <!-- <td
            v-if="editTermReport"
        >
            <v-select
                v-model="record.conduct"                               
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details               
                class="mt-1"
                @change="updateRecord" 
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
            <v-btn
               disabled
               block
               height="15"
               class="my-1"
               text
            > </v-btn>
        </td>
        <td v-else>
            {{ record.conduct }}
        </td> -->
        <td
            v-if="editTermReport"
            style="position:relative"
        >
            <v-textarea                                       
                dense
                rows="3"
                class="subject-comments"                                              
                maxlength="100"
                v-model="record.comment"
                @blur="updateRecord" 
                counter                           
            >                        
            </v-textarea>
            <v-btn
                text
                class="pl-0"
                style="font-size: 0.6rem; color:green; position:absolute; bottom:8px"
                height="20"
                @click="showPresetComments"
            >
                Preset Comment
            </v-btn>
        </td>
        <td
            v-else
            class="text-caption"
        >
            {{ record.comment}}
        </td>
        <td
            v-if="editTermReport"
        >
            <v-autocomplete
                v-model="recordEmployee"
                :items="employees"
                dense
                item-text="last_name"
                item-value="id"
                hide-details
                solo
                flat
                class="text-caption"
                @change="updateRecord"                           
            >
                <template v-slot:selection="{item}">
                    {{ item.first_name[0] + item.last_name[0]}}
                </template>

                <template v-slot:item=data>
                    {{ data.item.first_name[0] + ". " + data.item.last_name}}
                </template>
            </v-autocomplete> 
        </td>
        <td
            v-else
        >
            {{ record.employee_first_name[0] + record.employee_last_name[0]}}
        </td>
        <td
            class="px-0"
        >
            <v-btn 
                icon               
                @click="deleteSubject"
                v-show="editTermReport"
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        record: Object,
    },

    mounted: function () {               
        this.recordSubject = this.record.subject_id;
        this.recordEmployee = this.record.employee_id;
        this.initialize();
    },

    watch: {
        record: {
            handler () {                
                this.recordSubject = this.record.subject_id;
                this.recordEmployee = this.record.employee_id;
                this.initialize();                
            }
        },

        bottomSheet: {           
            handler (val) {
                if(
                    val.comment && 
                    this.record.subject_id === val.subject_id
                ) {
                    this.addPresetComment(val.comment);
                }
            }
        }

    },

    computed: {
        ...mapGetters({
            subjects: 'termReports/getSubjects',
            editTermReport: 'auth/getEditTermReport',
            employees: 'termReports/getEmployees',
            bottomSheet: 'termReports/getBottomSheet',
        }),      
    },

    data: () => ({
        recordSubject: null,
        recordEmployee: null,
        assesmentCourse: {
            attendance: 1,
            button: 'P',
            tooltip: 'Present',
            disable: false,
            modelAssesment: 'course_mark',
            modelAttendance: 'course_attendance',
            test: 2,
        },
        assesmentExam: {
            attendance: 1,
            button: 'P',
            tooltip: 'Present',
            disable: false,
            modelAssesment: 'exam_mark',
            modelAttendance: 'exam_attendance',
            test: 1,
        },
        commentsConduct: [
            { id: null, comment: null},
            { id: 'A', comment: 'Excellent' },
            { id: 'B', comment: 'Very Good' },
            { id: 'C', comment: 'Good' },
            { id: 'D', comment: 'Poor' },
            { id: 'E', comment: 'Unsatisfactory' },                
        ],
    }),

    methods: {
        ...mapMutations({
            setDeletedTermMark: 'termReports/setDeletedTermMark',
            setOverlay: 'termReports/setOverlaySubjectRecords',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setEditedTermMark: 'termReports/setEditedTermMark',
            setSaving: 'termReports/setSaving',
            setError: 'termReports/setError',
            setSaved: 'termReports/setSaved',
            setSavedToDb: 'termReports/setSavedToDb',
            setSnackbar: 'termReports/setSnackbar',
            setBottomSheet: 'termReports/setBottomSheet',
        }),

        ...mapActions({
            deleteStudentTermMark: 'termReports/deleteStudentTermMark',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            updateTermMarkSubject: 'termReports/updateTermMarkSubject',            
        }),

        initialize () {
            this.setAttendance(this.assesmentCourse, false, this.record.course_attendance);
            this.setAttendance(this.assesmentExam, false, this.record.exam_attendance);
        },

        async deleteSubject () {            
            this.setOverlay(true);           
            this.setDeletedTermMark(this.record);
            try {
                let response = await this.deleteStudentTermMark();
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        },

        async updateRecord () {
            // console.log(this.record);
            this.saveStatus('saving');
            this.record.new_subject_id = this.recordSubject;            
            
            this.setEditedTermMark(this.record);
            try {
                let response = await this.updateTermMarkSubject();
                // console.log(response);
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);
                this.saveStatus();   
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveStatus('error');
                this.setSnackbar({
                    display: true,
                    color: 'red',
                    text: `An Error has occured: ${error.response.data.message}`
                })
            }
        },

        saveStatus (status = 'saved') {
            this.setSaving(false);
            this.setError(false);
            this.setSaved(false);
            this.setSavedToDb(false);
            switch (status) {
                case 'saved':
                    this.setSaved(true);
                    break;
                case 'saving':
                    this.setSaving(true);
                    break;
                case 'error':
                    this.setError(true);
                    break;        
            }
        },

        setAttendance(assesment, update = false, attendance = null){
            if(attendance){
                assesment.attendance = attendance
                switch(attendance){
                    case 1:                    
                        assesment.button = 'P';
                        assesment.tooltip = 'Present';
                        assesment.disable = false;                        
                        break;
                    case 2:                    
                        assesment.button = 'Abs';
                        assesment.tooltip = 'Absent';
                        assesment.disable = true;
                        break;
                    case 3:                    
                        assesment.button = 'NW';
                        assesment.tooltip = 'No Work';
                        assesment.disable = true;
                        break;
                }
            }
            else{
                console.log(assesment);
                console.log(assesment['attendance'] );
                switch(assesment.attendance){
                    case 1:
                        console.log('case 1');
                        assesment.attendance = 2;
                        assesment.button = 'Abs';
                        assesment.tooltip = 'Absent';
                        this.record[assesment.modelAssesment] = null;
                        this.record[assesment.modelAttendance] = 2
                        assesment.disable = true;
                        break;
                    case 2:
                        // assesment.attendance = (assesment.test === 2) ? 3 : 1;
                        console.log('case 2');
                        if(assesment.test === 2){
                            assesment.attendance = 3;
                            assesment.button = 'NW';
                            assesment.tooltip = 'No Work';
                            this.record[assesment.modelAssesment] = null;
                            this.record[assesment.modelAttendance] = 3
                            assesment.disable = true;
                        }
                        else{
                            assesment.attendance = 1;
                            assesment.button = 'P';
                            assesment.tooltip = 'Present';
                            this.record[assesment.modelAttendance] = 1;
                            assesment.disable = false;
                        }
                        break;
                    case 3:
                        console.log('case 3');
                        assesment.attendance = 1;
                        assesment.button = 'P';
                        assesment.tooltip = 'Present';                    
                        this.record[assesment.modelAttendance] = 1
                        assesment.disable = false;
                        break;
                }
            }
            
            if(update) {
                console.log(assesment);
                this.updateRecord();
            }
            
        },

        showPresetComments () {
            this.setBottomSheet({
                display: true,
                subject_id: this.record.subject_id
            })
        },

        addPresetComment (comment) {
            let subjectComments = [];
            subjectComments.push(this.record.comment);
            subjectComments.push(comment);
            this.record.comment = subjectComments.join(' ').substring(0, 100);
            this.updateRecord();
        },
        
    }
}
</script>

<style scoped> 
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 6px;
    }

    ::v-deep .subject-comments .v-text-field__slot textarea{
        font-size: 12px;
        line-height: 1rem;
    }

    ::v-deep .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 30px
    }

    ::v-deep .v-input.subject-comments.v-textarea{
        margin-top: 12px;
        margin-bottom: 12px;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-select__selections{
        padding-left: 4px;
    }

    ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
        margin-top: 2px;
    }

    ::v-deep .v-counter{
        font-size: 0.7rem;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
        -moz-appearance: textfield;
        text-align: center;
    }
</style>

