<template>
    <v-card
        width="800"
        height="75vh"
        class="mx-auto pa-4"
        elevation="2"
        outlined
    >
        <v-card-title>
            Subject Student Assignment
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details=""
                clearable
            ></v-text-field>
        </v-card-title>
        <v-data-table
            class="mt-10"
            :headers="subjectHeaders"
            :items="subjects"
            :search="search"
            fixed-header
            height="45vh"
            :loading="loadingSubjects"            
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    text
                    outlined
                    color="primary"
                    @click="addItem(item)"
                >
                    <v-icon
                        left
                        medium
                        class="mr-2"
                        
                    >
                        mdi-account-multiple-plus
                    </v-icon>
                    View / Add Students
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog"
            max-width="800px"
            persistent
        >
            <v-card>
                 <v-card-title>
                    {{ editedSubject.title }}
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchStudentAssigned"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details=""
                        clearable
                    ></v-text-field>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="studentHeaders"
                        :items="studentsAssigned"
                        fixed-header                        
                        height="50vh"
                        :calculate-widths="true"                        
                        :disable-pagination="true"
                        :options="options"
                        :search="searchStudentAssigned"
                        :loading="loadingAssignedStudents"
                    >
                       <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                medium
                                class="mr-2"
                                @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template> 
                    </v-data-table>
                </v-card-text>
                <v-card-actions>                   
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        dark
                        class="add-student-btn"
                        @click="addStudent"
                       
                    >
                        Add Student
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="closeEdit"
                        outlined
                    >
                        Close
                    </v-btn>
                </v-card-actions>
                <v-overlay
                    absolute
                    :value="deleteOverlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >      
                    <v-progress-circular                       
                        transition="fade-transition" 
                        indeterminate 
                        size="64"                            
                        color="blue"
                    ></v-progress-circular>                    
                </v-overlay>      
            </v-card>
            <v-dialog
                v-model="dialogStudent"
                max-width="800px"               
            >
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-autocomplete
                                        v-model="studentSelected"
                                        :items="studentsSearch"
                                        item-text="name"
                                        item-value="id"                                        
                                        label="Student"                                        
                                        hide-details
                                        autofocus                                        
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ item.name }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name  + ' - ' + item.form_class}}
                                                </v-list-item-title>                  
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        v-model="teacherSelected"
                                        :items="teachers"
                                        item-text="name"
                                        item-value="id"
                                        label="Teacher"
                                        @change="setStudent"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>    
                    </v-card-text>
                    <v-overlay
                        absolute
                        :value="overlay"                    
                        color="grey lighten-5"
                        opacity="0.9"
                        class="pa-5"
                    >      
                        <v-progress-circular                            
                            transition="fade-transition" 
                            indeterminate 
                            size="64"                            
                            color="blue"
                        ></v-progress-circular>
                       
                    </v-overlay>                    
                </v-card>
            </v-dialog>
        </v-dialog>    
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },
    data: () => ({
        search: '',
        studentHeaders: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'student_id', width: '50'},
            {text: 'Last Name', value: 'last_name', width: '100'},
            {text: 'First Name', value: 'first_name', width: '100'},            
            {text: 'Gender', align: 'center', value: 'gender', width: '60'},            
            {text: 'Class',  value: 'form_class', width: '160'},            
            {text: 'Delete', value: 'actions', sortable: 'false', width: '50'}           
        ],
        subjectHeaders: [
            {text: 'Subject ID', align: 'center', sortable: 'false', value: 'id', width: '100'},
            {text: 'Subject', align: 'start', sortable: 'false', value: 'title', width: '300'},
            {text: '', value: 'actions', sortable: 'false'}
        ],
        students: [],
        studentsSearch: [],       
        studentsAssigned: [],
        subjects: [],
        teachers: [],
        studentSelected: '',
        subjectSelected: null,
        teacherSelected: null,
        loading: true,
        loadingSubjects: true,
        dialog: false,
        editedSubject: {},
        formClasses: [],
        saveStatus: '',
        saving: false,
        saved: false,
        saveError: false,
        options: {
            itemsPerPage: -1,
        },
        searchStudentAssigned: '',
        dialogStudent: false,
        overlay: false,
        deleteOverlay: false,
        loadingAssignedStudents: true,        
        next: false,
    }),
    methods: {
        ...mapActions({
            getStudents: 'subjectAssignments/getCurrentStudents',
            getFormClasses: 'timetable/getFormClasses',
            getSubjects: 'timetable/getSubjects',
            getStudentsAssigned: 'subjectAssignments/getStudentsAssigned',
            postStudentAssignment: 'subjectAssignments/postStudentAssignment',
            getEmployees: 'employees/getEmployees',
            deleteStudentSubjectAssignment: 'subjectAssignments/deleteSubjectAssignment'
        }),
        ...mapMutations({
           setSelectedSubject: 'subjectAssignments/setSubjectSelected',
           setStudentSelected: 'subjectAssignments/setStudentSelected',           
        }),
        async addItem(item){
            //console.log(item);
            this.editedSubject = item;
            this.setSelectedSubject(item.id);
            this.subjectSelected = item.id;
            // this.studentSelected = item.first_name + ' ' + item.last_name;
            // this.editedStudent = item;
            this.dialog = true;
            try {
                let response = await this.getStudentsAssigned();
                this.studentsAssigned = response.data;
                this.loadingAssignedStudents = false;
                //console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }
        },
        async initialize(){
            try {
                let response = await this.getStudents();                
                //this.students = response.data;
                this.setStudentsSearch(response.data);
                //console.log(response.data);

                response = await this.getFormClasses();
                this.formClasses = response.data;
                //console.log(response);

                response = await this.getSubjects();
                this.subjects = response.data;

                response = await this.getEmployees();
                this.setEmployees(response.data);
                //console.log(response);

                this.loadingSubjects = false;
                //console.log(response);

            } catch (error) {
                console.log(error.response);
                this.loading = false;
            }
        },        
        addStudent(){
            console.log('adding student...');
            this.dialogStudent = true;            
        },
        async deleteItem(item){
            console.log(item);
            this.deleteOverlay = true;
            this.setStudentSelected({
                subject_id: this.subjectSelected,
                student_id: item.student_id,
                employee_id: ''
            })
            try {
                let response = await this.deleteStudentSubjectAssignment();
                console.log(response);
                response = await this.getStudentsAssigned();
                this.studentsAssigned = response.data;
                this.deleteOverlay = false;
            } catch (error) {   
                console.log(error);
            }
        },
        closeEdit(){
            this.dialog = false;
            this.studentsAssigned = [];
        },
        async setStudent(){
            console.log(this.studentSelected);
            console.log(this.teacherSelected);
            if(this.studentSelected && this.teacherSelected){
                console.log('both fields not null');
                this.overlay = true;                
                this.setStudentSelected({
                    subject_id: this.subjectSelected,
                    student_id: this.studentSelected,
                    employee_id: this.teacherSelected
                })
                try {
                    let response = await this.postStudentAssignment();
                    console.log(response);

                    response = await this.getStudentsAssigned();
                    this.studentsAssigned = response.data;
                    this.studentSelected = null;
                    this.teacherSelected = null;                   

                } catch (error) {
                    console.log(error.response);
                }
                this.overlay = false;
                this.dialogStudent = false;
               
                
            }
            else{
                console.log('student field empty');
            }
            
        },
        closeStudentDialog(){
            this.dialogStudent = false;
        },
        setEmployees(data){
            data.forEach(record => {
                let teacher = {
                    name: record.first_name + ' ' + record.last_name,
                    id: record.id
                }
                this.teachers.push(teacher);
            })
        },
        setStudentsSearch(data){
            data.forEach(record => {
                let student = {
                    name: record.first_name + ' ' + record.last_name,
                    id: record.student_id,
                    form_class: record.form_class_id
                }
                this.studentsSearch.push(student);
                //console.log(this.studentsSearch);
            })
        }
    }
}
</script>