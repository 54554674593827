var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[(_vm.editTermReport)?_c('td',[_c('v-autocomplete',{staticClass:"text-caption",attrs:{"items":_vm.subjects,"dense":"","item-text":"abbr","item-value":"id","hide-details":"","solo":"","flat":""},on:{"change":_vm.updateRecord},model:{value:(_vm.recordSubject),callback:function ($$v) {_vm.recordSubject=$$v},expression:"recordSubject"}})],1):_c('td',[_vm._v(" "+_vm._s(_vm.record.abbr)+" ")]),(_vm.editTermReport)?_c('td',[_c('v-text-field',{staticClass:"mt-1",staticStyle:{"font-size":"0.9rem"},attrs:{"dense":"","hide-details":"","min":"0","max":"100","outlined":"","oninput":"if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min","disabled":_vm.assesmentCourse.disable,"filled":_vm.assesmentCourse.disable},on:{"blur":_vm.updateRecord},model:{value:(_vm.record.course_mark),callback:function ($$v) {_vm.$set(_vm.record, "course_mark", $$v)},expression:"record.course_mark"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1 pa-0",attrs:{"block":"","height":"15","depressed":"","tile":""},on:{"click":function($event){return _vm.setAttendance(_vm.assesmentCourse, true)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.assesmentCourse.button))])]}}],null,false,3264924643)},[_c('span',[_vm._v(_vm._s(_vm.assesmentCourse.tooltip))])])],1):_c('td',[_vm._v(" "+_vm._s(_vm.record.course_mark)+" ")]),(_vm.editTermReport)?_c('td',[_c('v-text-field',{staticClass:"mt-1",staticStyle:{"font-size":"0.9rem"},attrs:{"dense":"","hide-details":"","min":"0","max":"100","outlined":"","oninput":"if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min","disabled":_vm.assesmentExam.disable,"filled":_vm.assesmentExam.disable},on:{"blur":_vm.updateRecord},model:{value:(_vm.record.exam_mark),callback:function ($$v) {_vm.$set(_vm.record, "exam_mark", $$v)},expression:"record.exam_mark"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1 pa-0",attrs:{"block":"","height":"15","depressed":"","tile":""},on:{"click":function($event){return _vm.setAttendance(_vm.assesmentExam, true)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.assesmentExam.button))])]}}],null,false,138393443)},[_c('span',[_vm._v(_vm._s(_vm.assesmentExam.tooltip))])])],1):_c('td',[_vm._v(" "+_vm._s(_vm.record.exam_mark)+" ")]),(_vm.editTermReport)?_c('td',{staticStyle:{"position":"relative"}},[_c('v-textarea',{staticClass:"subject-comments",attrs:{"dense":"","rows":"3","maxlength":"100","counter":""},on:{"blur":_vm.updateRecord},model:{value:(_vm.record.comment),callback:function ($$v) {_vm.$set(_vm.record, "comment", $$v)},expression:"record.comment"}}),_c('v-btn',{staticClass:"pl-0",staticStyle:{"font-size":"0.6rem","color":"green","position":"absolute","bottom":"8px"},attrs:{"text":"","height":"20"},on:{"click":_vm.showPresetComments}},[_vm._v(" Preset Comment ")])],1):_c('td',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.record.comment)+" ")]),(_vm.editTermReport)?_c('td',[_c('v-autocomplete',{staticClass:"text-caption",attrs:{"items":_vm.employees,"dense":"","item-text":"last_name","item-value":"id","hide-details":"","solo":"","flat":""},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name[0] + item.last_name[0])+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.first_name[0] + ". " + data.item.last_name)+" ")]}}],null,false,4148331148),model:{value:(_vm.recordEmployee),callback:function ($$v) {_vm.recordEmployee=$$v},expression:"recordEmployee"}})],1):_c('td',[_vm._v(" "+_vm._s(_vm.record.employee_first_name[0] + _vm.record.employee_last_name[0])+" ")]),_c('td',{staticClass:"px-0"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.editTermReport),expression:"editTermReport"}],attrs:{"icon":""},on:{"click":_vm.deleteSubject}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }