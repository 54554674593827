<template>
    <v-row 
        align="center"
        justify="center"                    
    >
        <v-col
            cols="12"                       
        >
            <v-menu               
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"                           
                transition="scale-transistion"
                offset-y
                min-width="290px"                            
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="initialDate.date"
                        :label="label"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        autocomplete="false"
                        :rules="[() => !!initialDate.date || 'This field is required']"
                        :error-messages="errorMessages"
                        required
                        clearable                                                    
                    >
                        
                    </v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="initialDate.date"
                    @change="save"
                >                                
                </v-date-picker>                            
            </v-menu>
        </v-col>       
    </v-row>
</template>

<script>
export default {
    props: {
        label: String,
        initialDate: { date: null},
        rules: [],
        errorMessages: String,
        //initialDate: String,
    },

    data: function () {
        return {
            date: this.initialDate.date,
            //date: null,
            //date: this.initialDate,
            menu: false,
        }        
    },

    watch: {
        menu (val) {
            val && setTimeout(() => this.$refs.picker.activePicker = 'YEAR');
        },
    },

    methods: {
        save (date) {
            this.$refs.menu.save(date);
            //console.log(this.initialDate);
        }
    }
}
</script>