<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
    >
        <v-card
            class="pa-4"
            
        >
            <v-tabs
                v-model="tab"
                align-with-title
                grow
            >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>Set Current Term</v-tab>
                <v-tab>Term Archives</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card
                        flat
                    >
                        <v-card-text>
                            <v-select
                                label="Term"
                                :items="terms"
                                v-model="term"
                                prepend-icon="mdi-numeric-1-box-multiple-outline"
                            ></v-select>
                            <date-picker                    
                                v-bind:label="labelTermStartDate"
                                v-bind:initialDate="dateTermStart"                    
                            ></date-picker>

                            <date-picker                    
                                v-bind:label="labelTermEndDate"
                                v-bind:initialDate="dateTermEnd"
                            ></date-picker>

                            <date-picker                    
                                v-bind:label="labelNextTermStartDate"
                                v-bind:initialDate="dateNextTermStart"
                            ></date-picker>

                            <v-text-field
                                label="Total Number of Sessions"
                                prepend-icon="mdi-counter"
                                v-model="totalSessions"
                                clearable
                            ></v-text-field>
                        </v-card-text>
                       
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card
                        flat
                    >
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Term"
                                single-line
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :headers="headersTermHistory"
                                :items="availableTerms"
                                :search="search"
                                hide-default-footer
                                :loading="loading"
                                :custom-filter="filterTerms"
                                fixed-header
                                height="50vh"
                            >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr
                                            v-for="item in items"
                                            :key="item.id"
                                        >
                                            <td>
                                                {{ item.title }}
                                            </td>                                        
                                            <td>
                                                <div 
                                                    v-if="item.current == 1"
                                                    class="d-flex flex-column align-start"
                                                >
                                                    <v-icon 
                                                        color="primary"                                                    
                                                    >
                                                        mdi-clock-check-outline
                                                    </v-icon>
                                                    <div style="font-size:0.7rem" class="primary--text">Current</div>
                                                </div>
                                            </td>
                                            <td>
                                                <v-btn
                                                    small
                                                    outlined
                                                    color="primary"
                                                    @click="backdateTerm(item)"
                                                    :disabled="item.current == 1"
                                                >Set Term</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        
                        

                    </v-card>
                </v-tab-item>
            </v-tabs-items>

            <v-card-actions>
                <v-row>
                    <v-col cols="6">    
                        <v-btn
                            color="primary"
                            outlined
                            @click="validate"
                            block
                            :disabled="disableBtn"
                            :filled="disableBtn"
                        >
                            Set \ Update Term
                        </v-btn>
                    </v-col>
                    <v-col cols="6">    
                        <v-btn
                            
                            outlined
                            @click="close"
                            block
                            color="red"
                        >
                            Close
                        </v-btn>
                    </v-col>    
                </v-row>
            </v-card-actions>
            
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>
        
         <v-snackbar
            v-model="snackbar"
            timeout="5000"
            :color="snackColor"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from './DatePicker';

export default {
    components: {
        DatePicker,
    },

    props: {
        dialog: {
            type: Boolean,
            default: false,
        }
    },

    mounted: function () {
        this.initialize();
    },

    data: () => ({        
        dateTermStart: {date: null},
        dateTermEnd: {date: null},
        dateNextTermStart: {date: null},
        labelTermStartDate: 'Current Term Start',
        labelTermEndDate: 'Current Term End',
        labelNextTermStartDate: 'New Term Beginning',
        totalSessions: null,
        term: null,
        terms: [1,2,3],
        overlay: false,
        tab: null,
        headersTermHistory: [
            { text: 'Term', value: 'title', width: '200'},            
            { text: 'Current', value: 'current', align: 'center' },
            { text: '', value: 'actions', sortable: false}
        ],
        search: "",
        availableTerms: [],
        loading: false,
        snackbar: false,
        snackText: '',
        snackColor: '',
    }), 

    computed: {
        ...mapGetters({
            //
        }),
        
        disableBtn () {
            if(!this.tab || this.tab == 0) return false;
            return true;
         }
    },

    methods: {
        ...mapMutations({
            setDialog: 'settings/setDialogSetTerm',
            setSelectedItem: 'settings/setSelectedItem',
            setCurrentTerm: 'settings/setCurrentTerm',
            setTermRegistration: 'settings/setTermRegistrationData',
            setAcademicTermId: 'app/setAcademicTermId',
            setAcademicYearId: 'app/setAcademicYearId',
            setAppTerm: 'app/setTerm',
            setCurrentAcademicPeriod: 'app/setCurrentAcademicPeriod',
            setBackdateAcademicTermId: 'settings/setBackdateAcademicTermId',
        }),
        
        ...mapActions({
            getCurrentTerm: 'settings/getCurrentTerm',
            getNextTerm: 'settings/getNextTerm',
            postCurrentTerm: 'settings/postCurrentTerm',
            postTermRegistration: 'settings/postTermRegistration',
            getTerms: 'settings/getAvailableTerms',
            setBackdateTerm: 'settings/backdateTerm',
        }),

        async initialize () {
            this.overlay = true;
            try {
                const currentTermPromise = this.getCurrentTerm();
                const nextTermPromise = this.getNextTerm();
                const termsPromise = this.getTerms();

                const [
                    { data:dataTerm },
                    { data:dataNextTerm },
                    { data:dataTerms }
                ] = await Promise.all([
                    currentTermPromise,
                    nextTermPromise,
                    termsPromise
                ])

                // console.log(dataTerms);

                // response = await currentTermPromise;
                // this.dateTermStart.date = response.data.term_start;
                // this.dateTermEnd.date = response.data.term_end;
                // this.term = response.data.term;
                // this.totalSessions = response.data.total_sessions;

                this.dateTermStart.date = dataTerm.term_start;
                this.dateTermEnd.date = dataTerm.term_end;
                this.term = dataTerm.term;
                this.totalSessions = dataTerm.total_sessions;             

                // response = await nextTermPromise;                
                // this.dateNextTermStart.date = response.data.term_start;

                this.dateNextTermStart.date = dataNextTerm.term_start;

                this.parseTermHistory(dataTerms);

                // console.log(dataTerms);
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

            this.overlay = false;
        },

        parseTermHistory (data) {
            this.availableTerms = data.map((value, index) => {
                    if(value.current == 1) this.selectedItem = index;
                    return {
                        id: value.id,
                        title: value.year + ' Term ' + value.term,
                        subtitle: value.period,
                        current: value.current                                               
                    }
                })
        },

        validate () {
            this.overlay = true;
            if(this.dateTermStart.date && this.dateTermEnd.date && this.dateNextTermStart.date){
                this.setTerm();
            }
            else{
                this.overlay = false;
                this.$emit('display-snackbar', {
                    display: true,
                    color: 'red',
                    message: "Information Missing."
                })
            }
        },

        async setTerm () {
            let response = null;

            this.setCurrentTerm({
                term: this.term,
                term_start: this.dateTermStart.date,
                term_end: this.dateTermEnd.date,
                total_sessions: this.totalSessions,
                next_term_start: this.dateNextTermStart.date
            });

            console.log(this.totalSessions);

           

            this.setTermRegistration({
                new_term_beginning: this.dateNextTermStart.date,
                total_sessions: this.totalSessions
            })

            try {
                const postCurrentTermPromise = this.postCurrentTerm();
                const postTermRegistration = this.postTermRegistration();

                response = await postCurrentTermPromise;                
                console.log(response.status);

                response = await postTermRegistration;
                console.log(response);

                this.updateAppTermSettings();

                 this.$emit('display-snackbar', {
                    display: true,
                    color: 'primary',
                    message: "Term set successfully."
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.$emit('display-snackbar', {
                    display: true,
                    color: 'red',
                    message: "An Error has occured."
                })
            }
            this.overlay = false;
        },

        async updateAppTermSettings(){
            let response = null, academic_year_id = null, year = null;
            try {
                response = await this.getCurrentTerm();
                academic_year_id = response.data.academic_year_id;
                this.setAcademicTermId(response.data.id);
                this.setAcademicYearId(academic_year_id);
                this.setAppTerm(response.data.term);
                year = academic_year_id.toString().substr(0, 4) + '-' + academic_year_id.toString().substr(4);
                this.setCurrentAcademicPeriod(`Term: ${ response.data.term } Year: ${ year}`); 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async updateTermDetails () {

        },

        close () {            
            this.$emit('close');
        },

        filterTerms (value, search) {
            return value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        },

        async backdateTerm (item) {  
            //console.log(item);          
            this.setBackdateAcademicTermId(item.id);
            this.overlay = true;            
            try {
                await this.setBackdateTerm();
                this.initialize();
                this.snackbar = true;
                this.snackColor = "primary";
                this.snackText = `Term Set to ${item.title}`;               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackbar = true;
                this.snackColor = "red";
                this.snackText = "An Error has occured."                
            }            
        },


    }

}
</script>