import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.authenticated || false,
        admin: sessionStorage.getItem('admin') || null,
        user: sessionStorage.getItem('user') || {employee_id: null, employee_name: null},
        userName: '',    
        userRecord: null,       
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        resetPasswordShow: false,
        newPassword: '',
        displayPasswordChanged: false,        
        step: 1,
        employeeId: sessionStorage.getItem('employeeId') || null,
        resetEmployeeId: '',
        employeeSignedIn: sessionStorage.employeeSignedIn || null,
        employeeInitials: sessionStorage.getItem('employeeInitials') || null,   
        validUserName: false,
        credentials: {},
        permissions: sessionStorage.getItem('permissions') || [],
        editTermReport: false,              
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },     

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },       

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },        

        getStep(state){
            return state.step
        },
        
        getEmployeeId(state){
            return state.employeeId
        },

        getEmployeeSignedIn(state){
            return state.employeeSignedIn
        },

        getEmployeeInitials(state){
            return state.employeeInitials
        },
        
        getValidUserName(state){
            return state.validUserName
        },

        getUserName(state){
            return state.userName
        },

        getResetPasswordShow(state){
            return state.resetPasswordShow
        },

        getCredentials(state){
            return state.credentials
        },

        getPermissions(state){
            return state.permissions
        },

        getResetEmployeeId(state){
            return state.resetEmployeeId
        },

        getEditTermReport(state){
            return state.editTermReport
        },

        getAdmin (state) {
            return state.admin
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setUserId (state, value){
            state.user.student_id = value;
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },
        
        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },        

        setStep(state, value){
            state.step = value
        },

        setEmployeeId(state, value){
            state.employeeId = value
        },

        setEmployeeSignedIn(state, value){
            state.employeeSignedIn = value
        },

        setEmployeeInitials(state, value){
            state.employeeInitials = value
        },

        setValidUserName(state, value){
            state.validUserName = value
        },

        setUserName(state, value){
            state.userName = value
        },

        setCredentials(state, value){
            state.credentials = value
        },

        setPermissions(state, value){
            state.permissions = value
        },

        setResetEmployeeId(state, value){
            state.resetEmployeeId = value
        },

        setEditTermMark(state, value){
            state.editTermReport = value
        },

        setAdmin (state, value){
            state.admin = value
        }
    },

    actions: {

        user({ getters }){           
            console.log("getting user information...");
            let url = '/api/user-employee/' + getters.getUserName;
            return axios.get(url);
        },       

        userPermissions({ getters }){
            let url = '/api/user-permissions/'
            + getters.getEmployeeId;
            return axios.get(url);
        },
        
        async signIn({ getters, dispatch, commit }){
            try{
                await axios.get('/sanctum/csrf-cookie');
                return axios.post('/api/employee-login', getters.getCredentials)
            } catch (error) {
                dispatch('serverError', error);               
                commit('setOverlay', false);
            }            

        },

        async changePassword({ getters }){           
            let url = '/api/employee-change-password';
            return await axios.post(url, {
                "name" : getters.getUserName,
                "password" : getters.getNewPassword,
            })            
        },        

        async signOut({commit}){                       
            await axios.post('/logout')
            .then(response => {
                console.log(response.status);
            })
            sessionStorage.clear();
            return commit('setAuthenticated', false); 
        },        
        
       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },      

       resetPassword({ getters }){
           let url = '/api/reset-employee-password'
           console.log (getters.getResetEmployeeId)
           return axios.post(url, {
               employee_id : getters.getResetEmployeeId
           })
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

       serverError({ commit }, err ){            
            if(err.response){
                console.log(err.response)
                var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'data');                                     
                if( hasErrors && err.response.data.errors.message.length != 0){
                    commit('setLoginErrors', err.response.data.errors.message[0]);
                }
                else{
                    commit('setLoginErrors', err.response.statusText);
                }                    
                commit('setAuthenticated', false);
                commit('setOverlay', false);
            }
            
       }
    }
}