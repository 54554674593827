<template>
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="350"                
                elevation="2"
                outlined                
            >
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>Application Settings</v-toolbar-title>
                </v-toolbar>
                <v-list>                    
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="display(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon
                                    color="primary"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title >
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>

        <SetTerm
            v-bind:dialog="dialogs.setTerm"
            v-on:close="close"
            v-on:display-snackbar="displaySnackbar"
        ></SetTerm>

        <AddEditSubjects
            v-bind:dialog="dialogs.addEditSubjects"
            v-on:close="close"
        ></AddEditSubjects>

         <PromoteStudents
            v-if="dialogs.promoteStudents"
            v-bind:dialog-open="dialogs.promoteStudents"
            v-on:dialog-close="close"
            v-on:snackbar-open="displaySnackbar"
        ></PromoteStudents>

        <PostReports
            v-if="dialogs.postReports"
            v-bind:dialog-open="dialogs.postReports"
            v-on:dialog-close="close"
            v-on:snackbar-open="displaySnackbar"
        ></PostReports>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-row>        
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SetTerm from './SettingsSetTerm';
import AddEditSubjects from './SettingsAddEditSubjects.vue'
import PromoteStudents from './SettingsPromoteStudents.vue';
import PostReports from './SettingsPostReports.vue';
export default {
    components: {
        SetTerm,
        AddEditSubjects,
        PromoteStudents,
        PostReports,
    },

    data: () => ({        
        items: [
            {
                text: 'Advance / Backdate Term', 
                icon: 'mdi-calendar-cursor',
                value: 'setTerm',
            },
            {
                text: 'Add / Edit Subjects', 
                icon: 'mdi-bookshelf',
                value: 'addEditSubjects',
            },
            {
                text: 'Promote Students', 
                icon: 'mdi-account-arrow-up',
                value: 'promoteStudents'
            },
            {
                text: 'Post Reports', 
                icon: 'mdi-upload-lock',
                value: 'postReports'
            },
        ],
        dialogs: {
            setTerm: false,
            addEditSubjects: false,
            promoteStudents: false,
            postReports: false,
        },
        snackbar: {
            display: false,
            message: '',
            color: 'primary'
        }
    }),

    computed: {
        ...mapGetters({
            //
        })
    },

    methods: {
        ...mapActions({
            //
        }),

        ...mapMutations({
            //
        }),

        display({ value }){
            Object.keys(this.dialogs).forEach(key => {
                this.dialogs[key] = false;
                if(key === value){
                    this.dialogs[key] = true;
                }
            })
        },

        close(){
            Object.keys(this.dialogs).forEach(key => {
                this.dialogs[key] = false;
            })
        },

        displaySnackbar (snackbar) {
            this.snackbar.display = true;
            this.snackbar.message = snackbar.message;
            this.snackbar.color = snackbar.color;
        }

    }
}
</script>